.Modal p{
    text-align: center;
    font-size: 18px;
    padding: 15px;
}

.modal-content {
    border-radius: 10px;
}

.modal-body{
    padding: none;
}

.modal-header {
    border-bottom: none;
    background: #1B1B1B;
}

.modal-body{
    padding: 0 !important;
}

@media(min-width: 992px){
    .modal-lg, .modal-xl {
       --bs-modal-width: 500px !important;
   }
}

@media(max-width: 1350px){
    .Modal p {
        font-size: 1.5vw
    }
}

@media(max-width: 800px){
    .Modal p {
        font-size: 15px
    }
}

@media(max-width: 600px){
    .Modal p {
        font-size: 15px
    }
}