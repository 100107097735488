


.swiper {
    width: 100%;
    height: 95%;
}
  
.swiper-slide {

text-align: center;
font-size: 18px;
background: #fff;

/* Center slide text vertically */
display: flex;
justify-content: center;
align-items: center;
}
  
.swiper-slide-instagram {
display: block;
width: 100%;
height: 100%;
object-fit: cover;
}

.swiper-slide-instagram img{
    height: 370px;
    max-width: 100%;
    object-fit: cover;
}

.Swiper-svg-instagram{
    width: 40px !important;
    height: 40px !important;
    z-index: 2; 
    position: absolute;
    top: 5px;
    right: 5px;
    color: white;
}

@media screen and (max-width: 1040px) {
    .Swiper-svg-instagram{
        width: 20px !important;
        height: 20px !important; 
    }
    .swiper-slide-instagram img{
        height: 100%;
    }
}
  
@media screen and (min-width: 768px) {
    .swiper {
        width: 90%;
        height: 90%;
    }
}